@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .expense-list {
    max-height: calc(100vh - 25rem);
  }

  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }

  .lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #cef;
    border-color: #cef transparent #cef transparent;
  }
}